 
import { ProductOptionModel } from "@vp/product-options-ui";
import { V2 } from "@vp/wrangler-data-source";
import { optionValueMatchesRange } from "./optionValueMatchesRange";
import { mapWranglerAttributeOptionToChoice } from "./mapWranglerAttributeOptionToChoice";

export const mapWranglerAttributeToOption = async (
  prd: string, 
  version: string, 
  market: string,
  attribute: V2.StandardConfigurationAttribute,
  selections: Record<string, string>
): Promise<ProductOptionModel> => {
  const selectedKey = getSelectedKey(attribute, selections[attribute.key])

  const choices = [];
  for (const value of attribute.values) {
    // Performance penalty only paid on first async call so no reason to parallelize
    const choice = await mapWranglerAttributeOptionToChoice(prd, version, market, value);
    choices.push(choice);
  }

  return {
    key: attribute.key,
    name: attribute.name,
    style: attribute.key === "Quantity" ? "dropdown" : "buttons",
    selectedKey,
    choices,
    isRequired: true,
  };
}

const getSelectedKey = (attribute: V2.StandardConfigurationAttribute, selectedValue: string): string | undefined => {
  if (attribute.range) {
    const ranges = (attribute.values as V2.StandardConfigurationAttributeRangeValue[])
    .filter(v => v.isCompatible)

    return ranges.some((r) => optionValueMatchesRange(r, Number(selectedValue)))
      ? selectedValue
      : undefined
  } else {
    return attribute.values.filter((c) => selectedValue === c.key)[0]?.key ?? undefined;
  }
}
