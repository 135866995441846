import { WorkEntity } from "../../../providers/WorkEntityProvider";

export function createNewWorkFromSelections (work: WorkEntity, quantity: string, merchandisingSelections: Record<string, string>): WorkEntity {
    return {
        ...work,
        merchandising: {
            quantity: parseInt(quantity),
            merchandisingSelections
        }
    }
}