export async function realizeDocumentUrl(dtcUrl: string, authToken: string): Promise<any> {
    const config = {
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    };
    try {
        const response = await fetch(dtcUrl, config);
        if (response.ok) {
            return await response.json();
        }
        else {
            throw new Error(`Failed to realize document URL: ${response.statusText}`);
        }
    } catch (ex) {
      console.error(ex, "Design Transformation Realization failed", { dtcUrl });
    }
  }