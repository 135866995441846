import type { ProductOptionModel } from '@vp/product-options-ui'
import { V2 } from '@vp/wrangler-data-source'

import { mapWranglerAttributeToOption } from '../wds/mapWranglerToPoui'
import { createContext, type PropsWithChildren, useState, useEffect, useContext } from 'react'

export type ProductOptionsProviderProps = {
}

const ProductOptionsContext = createContext<ProductOptionModel[]>([])

export function ProductOptionsProvider(props: PropsWithChildren<ProductOptionsProviderProps>) {
    const [ productOptions, setProductOptions ] = useState<ProductOptionModel[]>([])

    const wdc = V2.useWranglerDataContext()

    useEffect(() => {
        let mounted = true;

        const applyMappingToOption = async () => {
            if (wdc.data?.attributes && wdc.data?.selections.productKey && wdc.data?.selections.productVersion && wdc.context.culture) {
                const selections = Object.fromEntries((wdc.data.selections?.selectedAttributes ?? []).map(({ key, value }) => [key, value]));
                const options = [];
                const market = wdc.context.culture.split('-')[1];

                for (const attribute of wdc.data.attributes ?? []) {
                    // Penalty for awaiting here is only paid on first call, so no reason to parallelize
                    const mappedOption = await mapWranglerAttributeToOption(wdc.data.selections.productKey, wdc.data.selections.productVersion, market, attribute, selections);
                    options.push(mappedOption);
                }
                
                if (mounted) {
                    setProductOptions(options)
                }
            }
        };

        
        applyMappingToOption();

        return () => {
            mounted = false;
        }
    }, [wdc.data, wdc.context.culture])
    
    return (
        <ProductOptionsContext.Provider value={productOptions}>
            {props.children}
        </ProductOptionsContext.Provider>
    )
}

export const useProductOptions = () => {
    const context = useContext(ProductOptionsContext)
    if (!context) {
        throw new Error('useProductOptions must be used within a ProductOptionsProvider')
    }
    return context
}