import { V2 } from "@vp/wrangler-data-source"
import { WorkEntity } from "../../providers/WorkEntityProvider"

export function getProductDetailsFromWork(workEntity: WorkEntity, culture: string, authToken: string): V2.WranglerGetRequest {
    const selectedAttributes = {
        ...workEntity.merchandising.merchandisingSelections,
        Quantity: workEntity.merchandising.quantity.toString() 
    }

    return {
        merchant: 'Vistaprint',
        culture,
        productKey: workEntity.product.key,
        productVersion: parseInt(workEntity.product.version),
        selectedAttributes,
        initialSelectedAttributes: selectedAttributes,
        requestor: 'ubik-pdc',
        merchandisingExperience: 'Post Design Config Page',
        authToken,
    }
}