const baseUrl = "https://siteflow.merch.vpsvc.com";

export interface OptionValues {
  [key: string]: string;
}

export interface SiteflowResponse {
  action: string;
  flowAction: string;
  predictedFlow: SiteflowPredictedFlow[];
  type: string;
  url: string;
  urls: string[];
}

interface SiteflowPredictedFlow {
  action: string;
  url: string;
  urls: string[];
}

export async function getNextStepV2(
    siteTenant: string,
    culture: string,
    productKey: string,
    productVersion: string,
    workId: string,
    experience: string,
    optimizelyEndUserIdCookie?: string,
    selectedOptionValues?: OptionValues
  ): Promise<any | undefined> {
    let requestUrl = `${baseUrl}/v2/nextstep/${siteTenant}/${culture}/${experience}/${productKey}/${productVersion}?requestor=ubik-pdc`;
    if (optimizelyEndUserIdCookie) {
      requestUrl += `&optimizelyEndUserId=${optimizelyEndUserIdCookie}`;
    }
    if (selectedOptionValues && Object.entries(selectedOptionValues).length > 0) {
      const formattedOptionValues = Object.entries(selectedOptionValues).reduce((acc, curr) => {
        return acc + `&optionValues[${encodeURIComponent(curr[0])}]=${encodeURIComponent(curr[1])}`;
      }, "");
      requestUrl += formattedOptionValues;
    }
    try {
      const resp = await fetch(requestUrl);
      if (resp.ok) {
        const response: SiteflowResponse = await resp.json() as SiteflowResponse;
        if (response) {
          const newUrl = response.url.replace("{workId}", workId);
          return {
            nextStep: newUrl,
            predictedSteps: mapFlowToSteps(response.predictedFlow),
          };
        }
      } else {
        throw new Error(`Failed to get next step: ${resp.statusText}`);
      }
    } catch (ex) {
      console.error(ex as Error, "Siteflow request failed", {
        requestUrl,
        culture,
        productKey,
        productVersion,
        workId,
        experience,
        optimizelyEndUserIdCookie,
        selectedOptionValues,
      });
    }
  }

function mapFlowToSteps(predictedFlow: SiteflowPredictedFlow[]): string[] {
  try {
    return predictedFlow.map((i) => i.url);
  } catch (e) {
    return [];
  }
}