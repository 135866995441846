import { getStatusConstraintUrl } from './pastaUrls';
import type { AuditedStatusConstraint } from './types';

const PASTA_HOST = 'https://pasta.products.vpsvc.com';

const cache = new Map<string, Array<AuditedStatusConstraint> | undefined>();

/**
 * Retrieves the pasta data if possible, and saves it to a local cache to avoid unnecessary network requests.
 * @param prd The product key eg. PRD-GIDUKALA
 * @param version The version of the product as a number
 * @param market The two character market code for the product, e.g. 'US', 'CA', 'GB', etc.
 * @param requestor The requestor's name, which is used for logging purposes.
 * @returns An array of constraints that apply to the current product in this market
 * @throws {Error} If the request fails after 3 attempts, an error is thrown.
 */
const getPastaData = async (prd: string, version: number | string, market: string, requestor: string): Promise<Array<AuditedStatusConstraint> | undefined> => {
  const pathAndParams = getStatusConstraintUrl(prd, version, market, requestor);

  if (cache.has(pathAndParams)) {
    return cache.get(pathAndParams);
  }

  let attempts = 0;
  const maxRetries = 3;

  while (attempts < maxRetries) {
    try {
      const response = await fetch(`${PASTA_HOST}${pathAndParams}`);
      if (response.status === 404) {
        return undefined;
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: Array<AuditedStatusConstraint> = await response.json() as Array<AuditedStatusConstraint>;
      cache.set(pathAndParams, data);
      return data;
    } catch (error: any) {
      attempts++;
      if (attempts >= maxRetries) {
        throw new Error(`Failed to fetch data after ${maxRetries} attempts: ${error?.message}`);
      }
    }
  }
};

export { getPastaData };
