import { UtilityDocumentSourceResponse } from "../../../clients/documentService/documentService";
import { WorkEntityDesign } from "../../../providers/WorkEntityProvider";

export function getDocumentDesignForWork(udsDocument: UtilityDocumentSourceResponse): WorkEntityDesign {
    return {
        designUrl: udsDocument._links.documentRevision.href,
        displayUrl: udsDocument._links.previewInstructions.href,
        manufactureUrl: udsDocument._links.drawingInstructions.href,
        docRefUrl: udsDocument._links.documentRevisionReference.href,
    }
}