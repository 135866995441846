import { ProductOptionsProvider } from '../providers/ProductOptionsProvider'
import AddToCartButton from './add-to-cart-button/add-to-cart-button'
import { ProductOptions } from './product-options/product-options'

export function PostDesignConfig() {
    // For now, we just show the configurator, but this is the root where other components will be addeed.
    return <ProductOptionsProvider>
        <ProductOptions/>
        <AddToCartButton />
    </ProductOptionsProvider>
}