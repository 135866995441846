import { IdentityContext, IdentityProvider, useLogger, useSwanFontKeys, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { WranglerDataSourceFromWork } from '../wds/WranglerDataSourceFromWork'
import { PostDesignConfig } from './PostDesignConfig'
import { SWAN_STYLE_KEY_MAP, SwanFontNameEnum } from '@vp/swan'
import { WorkEntityProvider } from '../providers/WorkEntityProvider'
import { SiteflowProvider } from '../providers/SiteflowProvider'

export interface Props {
  greeting: string
}

export const Fragment = (props: Props) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)
  
  useSwanStyleKeys(Object.keys(SWAN_STYLE_KEY_MAP) as (keyof typeof SWAN_STYLE_KEY_MAP)[])
  useSwanFontKeys([
    SwanFontNameEnum.GraphikRegular,
    SwanFontNameEnum.GraphikMedium,
    SwanFontNameEnum.Tiempos,
    SwanFontNameEnum.TiemposRegular
  ])

  const workId = userContext.queryStrings?.['workid']

  // We'll try to get the auth token from the identity provider, but if it's not available,
  // we'll use the one from the query string.
  const overrideAuthToken = userContext.queryStrings?.['authtoken']

  const createAuthConfig = (culture: string, isDevelopment = false) => ({
    culture,
    developmentMode: isDevelopment,
    options: {
      requireSession: true,
      customText: 'Sign in to see your account',
    },
  })

  return (
    <IdentityProvider auth={createAuthConfig(userContext.locale ?? 'en-us')}>
      <IdentityContext.Consumer>
        { (ctx) =>  {
            const ctxAuthToken = ctx?.identity?.authorizationHeader

            // If the token was provided on the query string, we'll prefer that, otherwise,
            // we'll use the one given to us by the auth context.
            const authTokenToUse = overrideAuthToken ?? ctxAuthToken ?? ""

            if (!authTokenToUse) {
              return <></>
            }

            return (
              <WorkEntityProvider workId={workId ?? ""} authToken={authTokenToUse}>
                <WranglerDataSourceFromWork authToken={authTokenToUse}>
                  <SiteflowProvider>
                    <PostDesignConfig />
                  </SiteflowProvider>
                </WranglerDataSourceFromWork>
              </WorkEntityProvider>
            )
          }
        }
      </IdentityContext.Consumer>
    </IdentityProvider>
  )
}
