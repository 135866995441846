import { V2 } from "@vp/wrangler-data-source";
import { WorkEntity } from "../../../providers/WorkEntityProvider";
import { realizeDocumentUrl } from "../../../clients/designTransformationService/designTransformationService";
import { createUdsDocument } from "../../../clients/documentService/documentService";
import { updateWork } from "../../../clients/workEntityService/workEntityService";
import { createNewWorkFromSelections } from "./createNewWorkFromSelections";
import { getDocumentDesignForWork } from "./getDocumentDesignForWork";
import { navigateToNextPage } from "./navigateToNextPage";

export async function addToCartOnClick(
    updating: boolean,
    authToken: string,
    workId: string,
    data?: V2.StandardConfigurator,
    work?: WorkEntity,
    nextStep?: string,
) {
    if (data && updating === false) {
        const quantity = data.selections.selectedAttributes?.find((selectedAttribute) => selectedAttribute.key === 'Quantity')?.value;
        const merchandisingSelections = Object.fromEntries(((data.selections?.selectedAttributes ?? []).filter((merchAttr) => merchAttr.key !== 'Quantity')).map(({ key, value }) => [key, value]));

        const changesPending = data.selections.document?.changesPending ?? false;
        const documentUrl = data?.selections?.document?.url
        if (work && quantity) {
            // create new work
            const newWork: WorkEntity = createNewWorkFromSelections(work, quantity, merchandisingSelections);

            // update document if necessary
            if (changesPending && !!documentUrl) {
                const document = await realizeDocumentUrl(documentUrl, authToken);
                const udsDocument = await createUdsDocument(document, authToken);
                newWork.design = {
                    ...newWork.design,
                    ...getDocumentDesignForWork(udsDocument)
                }
            }

            // update work
            const requestor = "ubik-pdc";
            await updateWork(workId, newWork, authToken, requestor);

            // TO DO Add tracking code from PPO-983

            // Navigate to next page
            navigateToNextPage(nextStep);
        }
    }
}