import { AttributeConfiguration } from "./types";

export function attributeConfigurationToString(config: AttributeConfiguration): string {
  const sortedEntries = Object.entries(config)
    .sort(([keyA, valueA], [keyB, valueB]) => {
      const keyComparison = keyA.localeCompare(keyB);
      if (keyComparison !== 0) {
        return keyComparison;
      }
      return String(valueA).localeCompare(String(valueB));
    });

  return sortedEntries.map(([key, value]) => `${key}:${value}`).join(';');
}