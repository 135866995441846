import React, { createContext, useState, ReactNode, useEffect, useContext } from 'react';
import { getNextStepV2, OptionValues } from '../clients/siteflow/siteflow';
import { useUserContext } from '@vp/ubik-context';
import { WorkEntityContext } from './WorkEntityProvider';
import { V2 } from '@vp/wrangler-data-source';

interface SiteflowContextProps {
    siteflowNextStep: string;
}

export const SiteflowContext = createContext<SiteflowContextProps | undefined>(undefined);

interface SiteflowProviderProps {
    children: ReactNode;
}

interface SiteflowData {
    nextStep: string;
    predictedSteps: string[];
}

export const SiteflowProvider: React.FC<SiteflowProviderProps> = ({ children }) => {
    const [siteflowNextStep, setSiteflowNextStep] = useState<string>('');
    const userContext = useUserContext();
    const workId = userContext.queryStrings?.['workid'] ?? ""
    const work = useContext(WorkEntityContext);
    const { data } = V2.useWranglerDataContext();
    const selections = data?.selections?.selectedAttributes ?? [];
    const selectedOptionValues: OptionValues = {};
    selections.forEach((selection) => {
        selectedOptionValues[selection.key] = selection.value;
    });

    useEffect(() => {
            async function getSiteflowNextStep() {
                if (work) {
                    const siteflowData: SiteflowData = await getNextStepV2(
                        userContext.tenant ?? "vistaprint",
                        userContext.locale,
                        work?.product.key,
                        work?.product.version,
                        workId,
                        "pdc",
                        "",
                        selectedOptionValues
                    )
                    if (siteflowData) {
                        setSiteflowNextStep(siteflowData.nextStep);
                    }
                }
            }
            getSiteflowNextStep()
        }, [userContext.tenant, userContext.locale, work?.product.key, work?.product.version, workId, JSON.stringify(selectedOptionValues)])

    return (
        <SiteflowContext.Provider value={{ siteflowNextStep }}>
            {children}
        </SiteflowContext.Provider>
    );
};