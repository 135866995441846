
export const getStatusConstraintUrl = (prd: string, version: number | string, market: string, requestor: string) => {
  const _prd = encodeURIComponent(prd);
  const _version = encodeURIComponent(version.toString());
  const _market = encodeURIComponent(market);
  const _requestor = encodeURIComponent(requestor);
  
  if (!_prd || !_version || !_market || !_requestor) {
    throw new Error('One or more required parameters are empty or undefined');
  }

  return `/api/v1/products/${_prd}/statusconstraints?version=${_version}&merchant=Vistaprint&market=${_market}&includeAuditDetails=false&requestor=${_requestor}`
}
