import { attributeConfigurationToString } from "./attributeConfigurationToString";
import { attributeMatchesConstraint } from "./attributeMatchesConstraint";
import { getPastaData } from "./pastaService";
import { AttributeConfiguration, AuditedStatusConstraint } from "./types";

const promiseCache = new Map<string, Promise<Array<AuditedStatusConstraint> | undefined>>();
const evaluationCache = new Map<string, boolean>();

export const isConstrainedAttribute = async (
  prd: string, 
  version: string | number,
  market: string,
  requestor: string,
  attribute: AttributeConfiguration
): Promise<boolean> => {
  const attributeConfigString = attributeConfigurationToString(attribute);
  if (evaluationCache.has(attributeConfigString)) {
    return evaluationCache.get(attributeConfigString)!;
  }

  const promiseCacheKey = `${prd}-${version}-${market}-${requestor}`;
  if (!promiseCache.has(promiseCacheKey)) {
    promiseCache.set(promiseCacheKey, getPastaData(prd, version, market, requestor));
  }

  const pastaData = await promiseCache.get(promiseCacheKey);
  
  const attributeConstrained = 
    pastaData?.some((constraint) => attributeMatchesConstraint(attribute, constraint)) ?? false;
  const productConstrained = 
    pastaData?.some((constraint) => 
      constraint.constraint === undefined && constraint.status === "Disabled"
  ) ?? false;
    
  evaluationCache.set(attributeConfigString, attributeConstrained || productConstrained);
  return attributeConstrained || productConstrained;
};
