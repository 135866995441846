import { ProductOptionListModel, ProductOptionRangeModel } from "@vp/product-options-ui";
import { V2 } from "@vp/wrangler-data-source";
import { isConstrainedAttribute } from "../pasta";

export const mapWranglerAttributeOptionToChoice = async (prd: string, version: string, market: string, option: V2.StandardConfigurationAttributeValue): Promise<ProductOptionListModel | ProductOptionRangeModel> => {
  if (V2.isRange(option)) {
    return mapRangeValueToProductOptionRangeModel(option);
  } else if (V2.isListValue(option)) {
    return await mapListValueToProductOptionListModel(prd, version, market, option);
  } else {
    throw new Error("Unsupported attribute value type - expected ProductOptionListModel or ProductOptionRangeModel");
  }
};

const mapRangeValueToProductOptionRangeModel = (option: V2.StandardConfigurationAttributeRangeValue): ProductOptionRangeModel => {
  return {
    minimum: option.minimum,
    maximum: option.maximum,
    increment: option.increment,
  };
};

const mapListValueToProductOptionListModel = async (prd: string, version: string, market: string, option: V2.StandardConfigurationAttributeListValue): Promise<ProductOptionListModel> => {
  let isConstrained = true; // Default to gate closed for PDC
  let isCompatible = option.isCompatible;
  const incompatibleReasons = option.incompatibleReasons ?? [];
  try {
    if (isCompatible && option.completedProduct?.[0]) {
      // This call is async but only for the first call, the rest are cached
      isConstrained = await isConstrainedAttribute(prd, version, market, "PDC-UBIK", option.completedProduct[0]);
      if (isConstrained) {
        // If the product is constrained, it should not be rendered on PDC at this time
        isCompatible = false; 
      }
    }
  } catch (error) {
    isConstrained = true;
    incompatibleReasons.push("PASTA_ERROR");
  }

  return {
    key: option.key,
    image: option.images?.[0],
    name: option.name,
    callouts: (option.badges ?? []).map((badge) => ({
      key: badge.key as string,
      text: badge.text,
    })),
    isCompatible,
    incompatibleReasons,
    description: option.marketingText,
    isOutOfStock: isConstrained,
  };
};
