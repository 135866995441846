import { ProductOptionModel } from "@vp/product-options-ui";
import { findModifiedSelection } from "./findModifiedSelection";
import { getSelectedOptions } from "./getSelectedOptions";
import { handleCompatibility } from "./handleCompatibility";

export function mergeInputAndSelectedOptions(
  inputSelections: Record<string, string>,
  productOptions?: ProductOptionModel[]
): Record<string, string> {
  const previousSelections = getSelectedOptions(productOptions);

  const { modifiedKey, modifiedValue } = findModifiedSelection(
    inputSelections,
    previousSelections
  );

  if (!modifiedKey || !modifiedValue) return {};

  const finalSelections = handleCompatibility(
    inputSelections,
    modifiedKey,
    modifiedValue,
    productOptions
  );

  return finalSelections;
}
