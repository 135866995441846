import { useState, JSX, PropsWithChildren, useEffect, useContext } from 'react'
import { V2 } from "@vp/wrangler-data-source"
import { useUserContext } from '@vp/ubik-context'
import { WorkEntityContext } from '../providers/WorkEntityProvider'
import { getProductDetailsFromWork } from '../clients/workEntityService/getProductDetailsFromWork'

export type WranglerDataFromWorkProps = {
    authToken: string

    /**
     * If the Wrangler details are passed in as props, then there's no need to call the work entity service to get
     * the details. We'll use this for server-side rendering to re-fetching the data client-side
     */
    wranglerDetails?: V2.WranglerGetRequest
}

export function WranglerDataSourceFromWork(props: PropsWithChildren<WranglerDataFromWorkProps>): JSX.Element {

    const [ productData, setProductData ] = useState<V2.WranglerGetRequest | undefined>(props.wranglerDetails)
    const userContext = useUserContext()
    const workEntity = useContext(WorkEntityContext)

    useEffect(() => {
        async function loadProductDetails() {
            if (workEntity) {
                const data = getProductDetailsFromWork(
                    workEntity, 
                    userContext.locale,
                    props.authToken
                )
                setProductData(data)
            }
        }

        // Server side, we'll already have these details so we don't need an effect to load them.
        if (!props.wranglerDetails) {
            loadProductDetails()
        }
    }, [workEntity, userContext.locale, props.authToken])

    if (!productData) { return <></> }

    return (
        <V2.ManagedWranglerSelectionsProvider {...productData} authToken={props.authToken} requestor="ubik-pdc">
            {props.children}
        </V2.ManagedWranglerSelectionsProvider>
    )
}
