export interface UtilityDocumentSourceResponse {
  _links: UtilityDocumentSourceLinks;
}

interface UtilityDocumentSourceLinks {
  self: UtilityDocumentSourceLink;
  documentRevision: UtilityDocumentSourceLink;
  documentReference: UtilityDocumentSourceLink;
  documentRevisionReference: UtilityDocumentSourceLink;
  drawingInstructions: UtilityDocumentSourceLink;
  previewInstructions: UtilityDocumentSourceLink;
}

interface UtilityDocumentSourceLink {
  href: string;
}

const hoursToCache = "1";
const baseUrl = "https://uds.documents.cimpress.io";

export async function createUdsDocument(
    document: string,
    authToken: string
  ): Promise<UtilityDocumentSourceResponse> {
    const requestUrl = `${baseUrl}/v3/documents?cacheForHours=${hoursToCache}`;
    const request = new Request(requestUrl, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(document)
    });
    try {
        const response = await fetch(request);
        if (response.ok) {
            return await response.json() as Promise<UtilityDocumentSourceResponse>;
        } else {
            throw new Error(`Failed to create UDS document: ${response.statusText}`);
        }
    } catch (ex) {
      throw console.error(ex, "Utility Document Source failed", { document });
    }
  }