import { AttributeConfiguration, AuditedStatusConstraint } from "./types";

export const attributeMatchesConstraint = (
  attributeConfiguration: AttributeConfiguration, 
  pastaConstraint: AuditedStatusConstraint
): boolean => {
  let listConstraintApplies = false;
  const hasListConstraint = !!pastaConstraint.constraint?.list && pastaConstraint.constraint?.list.length > 0;

  let rangeConstraintApplies = false;
  const hasRangeConstraint = !!pastaConstraint.constraint?.range && pastaConstraint.constraint?.range.length > 0;

  // doesn't use 'hasListConstraint' due to typescript checking
  if (pastaConstraint.constraint?.list && pastaConstraint.constraint?.list.length > 0) {
    listConstraintApplies = pastaConstraint.constraint.list.every(listOption => {
      const attributeValue = attributeConfiguration[listOption.name ?? ""];
      return attributeValue && listOption.values?.includes(attributeValue);
    });
  }

  // doesn't use 'hasRangeConstraint' due to typescript checking
  if (pastaConstraint.constraint?.range && pastaConstraint.constraint?.range.length > 0) {
    rangeConstraintApplies = pastaConstraint.constraint.range.every(rangeOption => {
      const attributeValue = parseFloat(attributeConfiguration[rangeOption.name ?? ""]);
      return attributeValue && rangeOption.ranges?.some(range => {
        const min = range.minimum;
        const max = range.maximum ?? Number.MAX_VALUE;
        return attributeValue >= min && attributeValue <= max;
      });
    });
  }

  const constrained = 
   (hasListConstraint && hasRangeConstraint && listConstraintApplies && rangeConstraintApplies) ||
   (hasListConstraint && !hasRangeConstraint && listConstraintApplies) ||
    (!hasListConstraint && hasRangeConstraint && rangeConstraintApplies);


  return constrained;
}
