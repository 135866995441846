import { isProductOptionModelList, ProductOptionModel } from "@vp/product-options-ui";

export function handleCompatibility(
  selections: Record<string, string>,
  modifiedKey: string,
  modifiedValue: string,
  options?: ProductOptionModel[],
  setIncompatible?: (name?: string) => void
): Record<string, string> {
  if (!options) return selections;

  const matchedAttribute = options.find((a) => a.key === modifiedKey);
  if (!matchedAttribute || !isProductOptionModelList(matchedAttribute)) {
    return selections;
  }

  const matchedChoice = matchedAttribute.choices.find((c) => c.key === modifiedValue);
  if (!matchedChoice) return selections;

  let finalSelections = { ...selections };

  if (matchedChoice.violations?.length) {
    matchedChoice.violations.forEach((v) => {
      delete finalSelections[v.attributeKey];
    });
    setIncompatible?.(matchedAttribute.name);
  } else if (matchedChoice.isCompatible === false) {
    finalSelections = { [modifiedKey]: modifiedValue };
    setIncompatible?.(matchedAttribute.name);
  } else {
    setIncompatible?.(undefined);
  }

  return finalSelections;
}
