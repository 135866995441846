import { V2 } from "@vp/wrangler-data-source";

const FLOATING_POINT_THRESHOLD = 0.01;

// This is copied directly from Wrangler
// https://gitlab.com/vistaprint-org/merchandising-technology/configurator/wrangler/-/blob/86f4c585ab356a237845edfe95ba71ee886e317d/src/providers/v2/selectedAttributesProviderV2.ts#L79
export function optionValueMatchesRange(range: V2.StandardConfigurationAttributeRangeValue, optionValue: number) {
  if (range.minimum >= 0 && optionValue < range.minimum) {
    return false;
  }

  if (range.maximum && optionValue > range.maximum) {
    return false;
  }

  const zeroBased = optionValue - (range.minimum ?? 0);
  const increment = range.increment ?? 1;

  // Lets divide the value by the increment and turn it into an integer.
  // Ideally, if the value matches the range, then we'd get a difference of 0.
  // But because of floating point math, the difference can be slightly off.
  const diff = Math.abs((zeroBased / increment) - Math.round(zeroBased / increment));
  return diff < FLOATING_POINT_THRESHOLD;
}
